import { useEffect, useState } from 'react';

export function useScreenSize() {
	const [size, setSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});
	useEffect(() => {
		function listener() {
			setSize({
				width: Math.min(window.outerWidth, window.innerWidth),
				height: window.innerHeight,
			});
		}
		window.addEventListener('resize', listener);
		return () => window.removeEventListener('resize', listener);
	}, []);
    
	return size;
}
