import { FC, HTMLAttributes, Suspense, useState } from 'react';
import block from 'utils/bem-css-module';

import { Hint } from 'components/Hint';
import { Link } from 'components/Link';
import { useScreenSize } from 'hooks/use-screen-size';
import { MapWithQrLazy } from 'pages/Map';

import { Show } from 'types/map';

import style from './map-column.scss';

const b = block(style);
const MAX_SCREEN_SIZE_WITH_MAP = 1044;

type Props = {
    maxWidth?: number;
	show: Show;
}

export const MapColumn: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
	className,
	show,
	maxWidth = MAX_SCREEN_SIZE_WITH_MAP,
	...rest
}) => {
	const [isHintShowed, setIsHintShowed] = useState(true);
	const { width } = useScreenSize();

	if (width <= maxWidth) {
		return null;
	}
	return (
		<div className={b().mix(className)} {...rest}>
			<Suspense fallback={<div/>}>
				<div onClick={() => setIsHintShowed(false)} className={b('map')}>
					<Hint
						horizontal='left'
						vertical='bottom'
						className={b('hint', { showed: isHintShowed })}
					>
						Адрес ПринтБокс можно выбрать на карте
					</Hint>
					<MapWithQrLazy show={show} />
				</div>
			</Suspense>
			<Link
				className={b('map-button')}
				url={`/printers?show=${show}&from=${window.location.pathname}`}
				target='_self'
			>
				Открыть карту
			</Link>
		</div>
	);
};
