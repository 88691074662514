import { OptionsColorType, OptionsDoublePageType } from 'types/point';

export const COLOR_OPTION_TEXT =  {
	[OptionsColorType.bw]: 'Ч/Б печать',
	[OptionsColorType.colorful]: 'Цветная печать',
};

export const DUPLEX_OPTION_TEXT = {
	[OptionsDoublePageType.singleSide]: 'Односторонняя',
	[OptionsDoublePageType.twoSide]: 'Двусторонняя',
	[OptionsDoublePageType.twoSideTop]: 'Двусторонняя (переплёт сверху)',
};

export const PRINT_ERRORS = {
	CHOOSE_DUPLEX: 'В принтере недостаточно бумаги, пожалуйста выберите двустороннюю печать.',
	CHOOSE_BW: 'В цветном принтере недостаточно бумаги, пожалуйста выберите черно-белую печать.',
	CHOOSE_BW_DUPLEX: 'В цветном принтере недостаточно бумаги, пожалуйста выберите черно-белую двустороннюю печать.',
	CHANGE_ADDRESS: 'В принтере недостаточно бумаги чтобы распечатать документ, пожалуйста уменьшите количество копий или выберите другой адрес печати.',
	COST_ERROR: 'Не удалось посчитать стоимость печати. Пожалуйста проверьте интернет соединение.',
	AFTER_PAY: (document_names: string[]) => `Не удалось распечатать документы: ${document_names.join(',')}. На вашем профиле сохранен баланс. Пожалуйста, попробуйте изменить принтеры или повторите попытку позже.`,
	NO_PRINTERS: 'На данный момент распечатать по выбранному адресу невозможно. Пожалуйста, выберите другой пункт печати.',
};

export const SCAN_ERRORS = {
	INTERNET: 'Не удалось запустить сканирование. Пожалуйста, проверьте интернет соединение и попробуйте позже.',
	COMMON: 'Не удалось запустить сканирование по этому адресу.',
};
