import { FC, HTMLProps } from 'react';
import block from 'utils/bem-css-module';

import { useScrollToTop } from 'hooks/use-scroll-to-top';

import { Show } from 'types/map';

import { MapColumn } from './map-column';

import style from './map-column-page-container.scss';

const b = block(style);

type Props = {
	show: Show;
}

export const MapColumnPageContainer: FC<Props & HTMLProps<HTMLDivElement>> = ({ 
	children, 
	className,
	show,
	...rest
}) => {
	useScrollToTop();
    
	return (
		<div 
			className={b().mix(className)}
			{...rest}
		>
			<div className={b('container')}>
				{children}
			</div>
			<MapColumn show={show} />
		</div>
	);
};
