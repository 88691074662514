import { FC, useCallback, useRef, useState } from 'react';
import block from 'utils/bem-css-module';

import { LanguageDropdown } from 'components/LanguageDropdown';
import { NavigationLink } from 'components/NavigationLink';
import { useClickOutside } from 'hooks/use-click-outside';

import { Language } from 'types/common';
import { User } from 'types/user';

import style from './header-mobile.scss';

const b = block(style);


type Props = {
	user: User | null;
}


export const HeaderMobile: FC<Props> = ({
	user,
}) => {
	const [open, setOpen] = useState(false);
	const menuRef = useRef<HTMLMenuElement>(null);

	const callback = useCallback(() => {
		setOpen(false);
		if (!menuRef.current) {
			return;
		}
		menuRef.current.scrollTop = 0;
	}, [menuRef]);

	useClickOutside(menuRef, callback);

	return (
		<div className={b()}>
			<LanguageDropdown language={Language.RU} />
			<menu className={b('menu')} ref={menuRef}>
				<input
					type='checkbox'
					className={b('toggle')}
					checked={open}
					onChange={() => setOpen(!open)}
					aria-label='открыть боковое меню'
				/>
				<span className={b('icon')} />
				<span className={b('icon')} />
				<span className={b('icon')} />
				<ul
					className={b('ul')}
					onBlur={() => setOpen(false)}
				>
					<NavigationLink to='/'>
						Печать А4
					</NavigationLink>
					<NavigationLink to='/copy_payment/start/'>
						Копирование А4
					</NavigationLink>
					<NavigationLink to='/services'>
						Все услуги
					</NavigationLink>
					<NavigationLink to='/print_operations'>
						Мои заказы
					</NavigationLink>
					<NavigationLink to='/printers'>
						Карта точек печати
					</NavigationLink>
					<NavigationLink to='/profile/conversations'>
						Написать в поддержку
					</NavigationLink>
					{!user || user.created_without_credentials ?
						<NavigationLink to='/login'>
							Вход / Регистрация
						</NavigationLink>
						:
						<NavigationLink to='/profile'>
							Профиль
						</NavigationLink>
					}
					<NavigationLink to='/policy'>
						Политика Конфиденциальности
					</NavigationLink>
					<NavigationLink to='/agreement'>
						Пользовательское Соглашение
					</NavigationLink>
					<NavigationLink to='/company'>
						Реквизиты
					</NavigationLink>
					<li className={b('copyright-text')}>
						Copyright &copy; PrintBox 2024.<br />
						Все Права Защищены.
					</li>
				</ul>
			</menu>
		</div>
	);
};
