export const apiPath = {
	CHAT: '/api/chat_messages/',
	PRINTERS: '/api/printers/?show_all=True',
	POINTS: '/api/points/',
	DOCUMENTS_UPLOAD: '/api/user_documents/upload_files/',
	MAP_POINTS: '/api/points/map_points/',
	SCANNERS: {
		GET: '/api/scanners/',
		OPERATIONS: '/api/scan_operations/',
	},
	DOCUMENTS: '/api/user_documents/',
	MESSAGES: '/api/support_messages/',
	CONVERSATIONS: '/api/conversations/',
	QUESTIONS: '/api/support_questions/',
	FEEDBACK: '/api/v1/feedback/send_feedback/',
	PRINT_ORDERS: '/api/print_orders/',
	OPERATIONS: '/api/operations/',
	COPY_OPERATIONS: '/api/copy_operations/',
	USER: {
		GET: '/api/users/profile/',
		CHANGE: '/api/users/change_name/',
		ORDERS: '/api/orders/',
		SET_COLOR_THEME: '/api/users/select_site_color_theme/',
		CREATE_FAKE_USER: '/api/v1/user/register_without_credentials/',
	},
	SUPPORT: {
		SB_REJECT: '/api/send_sb_report/',
	},
	FAVORITE_PRINTER: '/api/users/select_favorite_point/',
	FAVORITE_SCANNER: '/api/users/select_favorite_scanner/',
	CONFIRM_MAIL: '/api/v1/user/do_email_confirmation/',
	ADMIN: {
		PRINTERS: '/api/admin_printers/',
		SCANNERS: '/api/admin_scanners/',
		USERS: '/api/users/',
		ORDERS: '/api/get_order_statistic/',
		OPERATIONS: '/api/admin_print_operations/',
		MESSAGES: '/api/all_support_messages/',
		CONVERSATIONS: '/api/all_conversations/',
		CANCEL: '/api/cancel_operation/',
		STATISTIC: {
			SCANNERS: '/api/get_scanner_order_statistic/',
			PRINTERS: '/api/get_printer_order_statistic/',
		},
	},
	PBN: {
		FILES: '/api/pbn_source_files/',
		RESULT_FILES: '/api/pbn_result_files/',
	},
} as const;
