import { FC } from 'react';
import block from 'utils/bem-css-module';

import { Button } from 'components/Button';
import { HeaderUser } from 'components/HeaderUser';
import { LanguageDropdown } from 'components/LanguageDropdown';
import { NavigationLink } from 'components/NavigationLink';
import { ThemeToggle } from 'components/ThemeToggle';
import { useDropdown } from 'hooks/use-dropdown';

import { Language } from 'types/common';
import { User } from 'types/user';

import style from './right-menu.scss';

const b = block(style);

type Props = {
	user: User | null;
}

export const RightMenu: FC<Props> = ({
	user,
}) => {
	const { menuRef, switchIsOpen, open } = useDropdown();

	if (!user || user.created_without_credentials) {
		return (
			<section className={b()}>
				<Button buttonType='default' onClick={() => window.location.pathname ='/login/'} style={{ margin: 0 }}>
					Вход / Регистрация
				</Button>
				<LanguageDropdown language={Language.RU} />
				<ThemeToggle id='header-theme-toggle' />
			</section>
		);
	}

	return(
		<section className={b()}>
			<menu ref={menuRef}>
				<HeaderUser
					balance={user.balance}
					avatar={user.avatar}
					className={b('menu')}
					onClick={switchIsOpen}
				/>
				<ul className={b('dropdown', { 'active': open })}>
					<NavigationLink
						to='https://printbox.io/business/'
						target='_blank'
						rel='noopener noreferrer'
					>
						Стать партнером 🚀
					</NavigationLink>
					<NavigationLink
						to='/profile/info/'
					>
						Мой профиль
					</NavigationLink>
					<NavigationLink
						to='/logout/?next=/'
					>
						Выход
					</NavigationLink>
				</ul>
			</menu>
			<LanguageDropdown language={Language.RU} />
			<ThemeToggle id='header-theme-toggle' />
		</section>
	);
};
